<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Banque de questions</h1>
<div class="flex mb-4 justify-content-end">
  <pf-action-button
    *ngIf="canCreate"
    label="Créer une nouvelle question"
    customIconClass="pi pi-plus"
    [routerLink]="questionsRoute"
  ></pf-action-button>
</div>

<div>
  <app-question-list>
    <ng-template #templateActions let-question>
      <div class="flex">
        <p-button
          icon="pi pi-eye"
          [rounded]="true"
          size="large"
          ariaLabel="Voir la question"
          pTooltip="Voir la question"
          tooltipPosition="top"
          [disabled]="!canShow"
          (click)="openViewQuestionModal(question.id)"
        ></p-button>
        <p-button
          icon="pi pi-file-edit"
          [rounded]="true"
          size="large"
          ariaLabel="Modifier la question"
          pTooltip="Modifier la question"
          tooltipPosition="top"
          [disabled]="!canEdit"
          [routerLink]="getQuestionIdRoute(question.id)"
        ></p-button>
        <p-button
          icon="pi pi-pause-circle"
          styleClass="p-button-danger"
          [rounded]="true"
          size="large"
          ariaLabel="Suspendre la question"
          pTooltip="Suspendre la question"
          tooltipPosition="top"
          [disabled]="!canSuspend(question)"
          (click)="openSuspendModal(question)"
        ></p-button>
      </div>
    </ng-template>
  </app-question-list>
</div>
