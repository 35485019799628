import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  ContentChild,
  input,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { Category } from '@app/models/question/category.model';
import { Theme } from '@app/models/question/theme.model';
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';
import { AdminRoute, RouteLabel } from '@app/utils/routes';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { QuestionStatusComponent } from '@components/questions/question-status/question-status.component';
import { Question } from '@models/question/question.model';
import {
  QuestionStatus,
  QuestionStatusInfos,
} from '@models/question/questionStatus.model';
import { TranslateModule } from '@ngx-translate/core';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { CategoryService } from '@services/category.service';
import { MediaService } from '@services/media.service';
import { QuestionService } from '@services/question.service';
import { ThemeService } from '@services/theme.service';
import { joinArray, renderList } from '@utils/stringHelper';
import {
  Filter,
  PageRequest,
  PfActionButtonComponent,
  PfTableComponent,
  TableColumn,
} from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-question-list',
  standalone: true,
  templateUrl: './question-list.component.html',
  imports: [
    ButtonModule,
    CommonModule,
    PfActionButtonComponent,
    RouterLink,
    SpinnerComponent,
    TableModule,
    TranslateModule,
    TruncatePipeModule,
    BreadcrumbComponent,
    TooltipModule,
    ThumbnailComponent,
    PfTableComponent,
    QuestionStatusComponent,
    MultiSelectModule,
    TagModule,
    FormsModule,
    PluralizePipeModule,
    SkeletonModule,
  ],
})
export class QuestionListComponent implements OnInit, OnDestroy {
  questions = this.questionService.signalListPaginated;
  isTableLoading = this.mediaService.signalImageLoader;
  isFetching = signal(true);
  isLoading = computed(() => this.isFetching() || this.isTableLoading());

  initialFilter = signal<Filter[]>([
    {
      field: 'questionStatus',
      value: [QuestionStatus.VALIDATED],
      matchMode: 'contains',
    },
  ]);

  // initialisation du tableau de colonnes
  columns = signal<TableColumn[]>([
    { name: 'Enoncé', sortName: 'description' },
    {
      name: 'Média',
    },
    {
      name: 'Thème(s)',
    },
    {
      name: 'Catégorie(s)',
    },
    {
      name: 'Statut(s)',
    },
    { name: '' },
  ]);

  request = input<PageRequest>({});
  currentRequest: PageRequest = {};

  choices: { name: string; id: string }[] = [];
  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;
  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Questions },
  ];

  @ContentChild('templateActions', { static: true })
  templateActions: TemplateRef<{
    $implicit: Question;
  }> | null = null;

  constructor(
    private categoryService: CategoryService,
    private themeService: ThemeService,
    public questionService: QuestionService,
    private mediaService: MediaService,
  ) {}

  ngOnInit(): void {
    this.currentRequest = {
      ...this.request(),
    };
    this.isFetching.set(true);

    const categories$ = this.categoryService.listAllCategories();
    const themes$ = this.themeService.list();
    const choices$ = of(
      Object.entries(QuestionStatusInfos).map(([id, value]) => {
        return { name: value.label, id };
      }),
    );
    forkJoin([categories$, themes$, choices$]).subscribe(
      ([categories, themes, choices]) => {
        const categoryFilters = categories.map((cat: Category) => ({
          name: cat.name,
          id: cat.id,
        }));

        const themesFilters = themes.theme?.map((th: Theme) => ({
          name: th.name,
          id: th.id,
        }));

        if (themesFilters != null && categoryFilters != null) {
          this.columns.set([
            {
              name: 'Enoncé',
              sortName: 'description',
              filter: {
                field: 'description',
                mode: 'equals',
                type: 'text',
                placeholder: "Choisir dans l'énoncé",
              },
            },
            {
              name: 'Média',
            },
            {
              name: 'Thème(s)',
              filter: {
                field: 'themes',
                mode: 'equals',
                type: 'text',
                placeholder: 'Choisir le(les) thèmes',
                data: themesFilters,
              },
            },
            {
              name: 'Catégorie(s)',
              filter: {
                field: 'categories',
                mode: 'equals',
                type: 'text',
                placeholder: 'Choisir la(les) catégories',
                data: categoryFilters,
              },
            },
            {
              name: 'Statut(s)',
              filter: {
                field: 'questionStatus',
                mode: 'equals',
                type: 'text',
                placeholder: 'Choisir le(s) statut(s)',
                data: choices,
              },
            },
            { name: '' },
          ]);
        }

        this.isFetching.set(false);
      },
    );
  }

  onParametersChangedHandler(event: PageRequest): void {
    // WARN : la librairie pf-ui envoie deux évènements à l'initialisation (un sans le filtre et le deuxième avec)
    // TRICKS : on bloque le premier appel pour éviter l'envoi de deux requêtes
    if (event?.filters !== '%7B%7D') {
      this.currentRequest = {
        ...this.currentRequest,
        ...event,
      };
      this.questionService
        .listPaginated(this.currentRequest)
        .pipe(
          tap(() => {
            this.isFetching.set(true);
          }),
        )
        .subscribe(() => {
          this.isFetching.set(false);
        });
    }
  }

  getTooltipDescription(question: Question): string {
    return joinArray(
      [question.description, question.secondDescription],
      '\r\n',
    );
  }

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH =
    MAX_QUESTION_DESCRIPTION_LENGTH;
  protected readonly QuestionStatus = QuestionStatus;

  ngOnDestroy(): void {
    this.questions?.set?.(undefined);
  }

  protected readonly renderList = renderList;
}
